import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as _91id_93ZSHYG36bYlMeta } from "/app/pages/depot/addsavingplan/[id].vue?macro=true";
import { default as _91id_93UdHwfjdwjWMeta } from "/app/pages/depot/detail/[id].vue?macro=true";
import { default as _91type_93Pa17IBXBmCMeta } from "/app/pages/depot/new/[type].vue?macro=true";
import { default as index0wgnfIpHJAMeta } from "/app/pages/inbox-message/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as verify3TBdhdd8KdMeta } from "/app/pages/profile/verify.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as request_45passwordl2SNOQaXmSMeta } from "/app/pages/request-password.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as reset_45pinWOy7bpb3UUMeta } from "/app/pages/reset-pin.vue?macro=true";
import { default as simulatork6R9PxQ47nMeta } from "/app/pages/simulator.vue?macro=true";
import { default as indexOP43DqW8IuMeta } from "/app/pages/support-tickets/index.vue?macro=true";
import { default as _91_91depotid_93_93QD3qz9OzusMeta } from "/app/pages/trading/buy/[[depotid]].vue?macro=true";
import { default as _91_91depotid_93_93b1OCaxcfHVMeta } from "/app/pages/trading/delivery/[[depotid]].vue?macro=true";
import { default as _91_91depotid_93_93OjPU9sT6FEMeta } from "/app/pages/trading/sell/[[depotid]].vue?macro=true";
import { default as _91_91depotid_93_93495L2tlMXiMeta } from "/app/pages/trading/transfer/[[depotid]].vue?macro=true";
export default [
  {
    name: dashboardJu37u7k0toMeta?.name ?? "dashboard___de",
    path: dashboardJu37u7k0toMeta?.path ?? "/de/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    alias: dashboardJu37u7k0toMeta?.alias || [],
    redirect: dashboardJu37u7k0toMeta?.redirect,
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardJu37u7k0toMeta?.name ?? "dashboard___en",
    path: dashboardJu37u7k0toMeta?.path ?? "/en/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    alias: dashboardJu37u7k0toMeta?.alias || [],
    redirect: dashboardJu37u7k0toMeta?.redirect,
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardJu37u7k0toMeta?.name ?? "dashboard___fr",
    path: dashboardJu37u7k0toMeta?.path ?? "/fr/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    alias: dashboardJu37u7k0toMeta?.alias || [],
    redirect: dashboardJu37u7k0toMeta?.redirect,
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZSHYG36bYlMeta?.name ?? "depot-addsavingplan-id___de",
    path: _91id_93ZSHYG36bYlMeta?.path ?? "/de/depot/addsavingplan/:id()",
    meta: _91id_93ZSHYG36bYlMeta || {},
    alias: _91id_93ZSHYG36bYlMeta?.alias || [],
    redirect: _91id_93ZSHYG36bYlMeta?.redirect,
    component: () => import("/app/pages/depot/addsavingplan/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZSHYG36bYlMeta?.name ?? "depot-addsavingplan-id___en",
    path: _91id_93ZSHYG36bYlMeta?.path ?? "/en/depot/addsavingplan/:id()",
    meta: _91id_93ZSHYG36bYlMeta || {},
    alias: _91id_93ZSHYG36bYlMeta?.alias || [],
    redirect: _91id_93ZSHYG36bYlMeta?.redirect,
    component: () => import("/app/pages/depot/addsavingplan/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZSHYG36bYlMeta?.name ?? "depot-addsavingplan-id___fr",
    path: _91id_93ZSHYG36bYlMeta?.path ?? "/fr/depot/addsavingplan/:id()",
    meta: _91id_93ZSHYG36bYlMeta || {},
    alias: _91id_93ZSHYG36bYlMeta?.alias || [],
    redirect: _91id_93ZSHYG36bYlMeta?.redirect,
    component: () => import("/app/pages/depot/addsavingplan/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93UdHwfjdwjWMeta?.name ?? "depot-detail-id___de",
    path: _91id_93UdHwfjdwjWMeta?.path ?? "/de/depot/detail/:id()",
    meta: _91id_93UdHwfjdwjWMeta || {},
    alias: _91id_93UdHwfjdwjWMeta?.alias || [],
    redirect: _91id_93UdHwfjdwjWMeta?.redirect,
    component: () => import("/app/pages/depot/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93UdHwfjdwjWMeta?.name ?? "depot-detail-id___en",
    path: _91id_93UdHwfjdwjWMeta?.path ?? "/en/depot/detail/:id()",
    meta: _91id_93UdHwfjdwjWMeta || {},
    alias: _91id_93UdHwfjdwjWMeta?.alias || [],
    redirect: _91id_93UdHwfjdwjWMeta?.redirect,
    component: () => import("/app/pages/depot/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93UdHwfjdwjWMeta?.name ?? "depot-detail-id___fr",
    path: _91id_93UdHwfjdwjWMeta?.path ?? "/fr/depot/detail/:id()",
    meta: _91id_93UdHwfjdwjWMeta || {},
    alias: _91id_93UdHwfjdwjWMeta?.alias || [],
    redirect: _91id_93UdHwfjdwjWMeta?.redirect,
    component: () => import("/app/pages/depot/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91type_93Pa17IBXBmCMeta?.name ?? "depot-new-type___de",
    path: _91type_93Pa17IBXBmCMeta?.path ?? "/de/depot/new/:type()",
    meta: _91type_93Pa17IBXBmCMeta || {},
    alias: _91type_93Pa17IBXBmCMeta?.alias || [],
    redirect: _91type_93Pa17IBXBmCMeta?.redirect,
    component: () => import("/app/pages/depot/new/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93Pa17IBXBmCMeta?.name ?? "depot-new-type___en",
    path: _91type_93Pa17IBXBmCMeta?.path ?? "/en/depot/new/:type()",
    meta: _91type_93Pa17IBXBmCMeta || {},
    alias: _91type_93Pa17IBXBmCMeta?.alias || [],
    redirect: _91type_93Pa17IBXBmCMeta?.redirect,
    component: () => import("/app/pages/depot/new/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_93Pa17IBXBmCMeta?.name ?? "depot-new-type___fr",
    path: _91type_93Pa17IBXBmCMeta?.path ?? "/fr/depot/new/:type()",
    meta: _91type_93Pa17IBXBmCMeta || {},
    alias: _91type_93Pa17IBXBmCMeta?.alias || [],
    redirect: _91type_93Pa17IBXBmCMeta?.redirect,
    component: () => import("/app/pages/depot/new/[type].vue").then(m => m.default || m)
  },
  {
    name: index0wgnfIpHJAMeta?.name ?? "inbox-message___de",
    path: index0wgnfIpHJAMeta?.path ?? "/de/inbox-message",
    meta: index0wgnfIpHJAMeta || {},
    alias: index0wgnfIpHJAMeta?.alias || [],
    redirect: index0wgnfIpHJAMeta?.redirect,
    component: () => import("/app/pages/inbox-message/index.vue").then(m => m.default || m)
  },
  {
    name: index0wgnfIpHJAMeta?.name ?? "inbox-message___en",
    path: index0wgnfIpHJAMeta?.path ?? "/en/inbox-message",
    meta: index0wgnfIpHJAMeta || {},
    alias: index0wgnfIpHJAMeta?.alias || [],
    redirect: index0wgnfIpHJAMeta?.redirect,
    component: () => import("/app/pages/inbox-message/index.vue").then(m => m.default || m)
  },
  {
    name: index0wgnfIpHJAMeta?.name ?? "inbox-message___fr",
    path: index0wgnfIpHJAMeta?.path ?? "/fr/inbox-message",
    meta: index0wgnfIpHJAMeta || {},
    alias: index0wgnfIpHJAMeta?.alias || [],
    redirect: index0wgnfIpHJAMeta?.redirect,
    component: () => import("/app/pages/inbox-message/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___de",
    path: indexN6pT4Un8hYMeta?.path ?? "/de",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: indexN6pT4Un8hYMeta?.path ?? "/fr",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___de",
    path: loginhHM0vSTW5jMeta?.path ?? "/de/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___en",
    path: loginhHM0vSTW5jMeta?.path ?? "/en/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___fr",
    path: loginhHM0vSTW5jMeta?.path ?? "/fr/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile___de",
    path: indexaeouN7Kzh7Meta?.path ?? "/de/profile",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile___en",
    path: indexaeouN7Kzh7Meta?.path ?? "/en/profile",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile___fr",
    path: indexaeouN7Kzh7Meta?.path ?? "/fr/profile",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: verify3TBdhdd8KdMeta?.name ?? "profile-verify___de",
    path: verify3TBdhdd8KdMeta?.path ?? "/de/profile/verify",
    meta: verify3TBdhdd8KdMeta || {},
    alias: verify3TBdhdd8KdMeta?.alias || [],
    redirect: verify3TBdhdd8KdMeta?.redirect,
    component: () => import("/app/pages/profile/verify.vue").then(m => m.default || m)
  },
  {
    name: verify3TBdhdd8KdMeta?.name ?? "profile-verify___en",
    path: verify3TBdhdd8KdMeta?.path ?? "/en/profile/verify",
    meta: verify3TBdhdd8KdMeta || {},
    alias: verify3TBdhdd8KdMeta?.alias || [],
    redirect: verify3TBdhdd8KdMeta?.redirect,
    component: () => import("/app/pages/profile/verify.vue").then(m => m.default || m)
  },
  {
    name: verify3TBdhdd8KdMeta?.name ?? "profile-verify___fr",
    path: verify3TBdhdd8KdMeta?.path ?? "/fr/profile/verify",
    meta: verify3TBdhdd8KdMeta || {},
    alias: verify3TBdhdd8KdMeta?.alias || [],
    redirect: verify3TBdhdd8KdMeta?.redirect,
    component: () => import("/app/pages/profile/verify.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register___de",
    path: registermu8R7rSP5uMeta?.path ?? "/de/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register___en",
    path: registermu8R7rSP5uMeta?.path ?? "/en/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register___fr",
    path: registermu8R7rSP5uMeta?.path ?? "/fr/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordl2SNOQaXmSMeta?.name ?? "request-password___de",
    path: request_45passwordl2SNOQaXmSMeta?.path ?? "/de/request-password",
    meta: request_45passwordl2SNOQaXmSMeta || {},
    alias: request_45passwordl2SNOQaXmSMeta?.alias || [],
    redirect: request_45passwordl2SNOQaXmSMeta?.redirect,
    component: () => import("/app/pages/request-password.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordl2SNOQaXmSMeta?.name ?? "request-password___en",
    path: request_45passwordl2SNOQaXmSMeta?.path ?? "/en/request-password",
    meta: request_45passwordl2SNOQaXmSMeta || {},
    alias: request_45passwordl2SNOQaXmSMeta?.alias || [],
    redirect: request_45passwordl2SNOQaXmSMeta?.redirect,
    component: () => import("/app/pages/request-password.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordl2SNOQaXmSMeta?.name ?? "request-password___fr",
    path: request_45passwordl2SNOQaXmSMeta?.path ?? "/fr/request-password",
    meta: request_45passwordl2SNOQaXmSMeta || {},
    alias: request_45passwordl2SNOQaXmSMeta?.alias || [],
    redirect: request_45passwordl2SNOQaXmSMeta?.redirect,
    component: () => import("/app/pages/request-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password52TOS9kgCUMeta?.name ?? "reset-password___de",
    path: reset_45password52TOS9kgCUMeta?.path ?? "/de/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    alias: reset_45password52TOS9kgCUMeta?.alias || [],
    redirect: reset_45password52TOS9kgCUMeta?.redirect,
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password52TOS9kgCUMeta?.name ?? "reset-password___en",
    path: reset_45password52TOS9kgCUMeta?.path ?? "/en/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    alias: reset_45password52TOS9kgCUMeta?.alias || [],
    redirect: reset_45password52TOS9kgCUMeta?.redirect,
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password52TOS9kgCUMeta?.name ?? "reset-password___fr",
    path: reset_45password52TOS9kgCUMeta?.path ?? "/fr/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    alias: reset_45password52TOS9kgCUMeta?.alias || [],
    redirect: reset_45password52TOS9kgCUMeta?.redirect,
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45pinWOy7bpb3UUMeta?.name ?? "reset-pin___de",
    path: reset_45pinWOy7bpb3UUMeta?.path ?? "/de/reset-pin",
    meta: reset_45pinWOy7bpb3UUMeta || {},
    alias: reset_45pinWOy7bpb3UUMeta?.alias || [],
    redirect: reset_45pinWOy7bpb3UUMeta?.redirect,
    component: () => import("/app/pages/reset-pin.vue").then(m => m.default || m)
  },
  {
    name: reset_45pinWOy7bpb3UUMeta?.name ?? "reset-pin___en",
    path: reset_45pinWOy7bpb3UUMeta?.path ?? "/en/reset-pin",
    meta: reset_45pinWOy7bpb3UUMeta || {},
    alias: reset_45pinWOy7bpb3UUMeta?.alias || [],
    redirect: reset_45pinWOy7bpb3UUMeta?.redirect,
    component: () => import("/app/pages/reset-pin.vue").then(m => m.default || m)
  },
  {
    name: reset_45pinWOy7bpb3UUMeta?.name ?? "reset-pin___fr",
    path: reset_45pinWOy7bpb3UUMeta?.path ?? "/fr/reset-pin",
    meta: reset_45pinWOy7bpb3UUMeta || {},
    alias: reset_45pinWOy7bpb3UUMeta?.alias || [],
    redirect: reset_45pinWOy7bpb3UUMeta?.redirect,
    component: () => import("/app/pages/reset-pin.vue").then(m => m.default || m)
  },
  {
    name: simulatork6R9PxQ47nMeta?.name ?? "simulator___de",
    path: simulatork6R9PxQ47nMeta?.path ?? "/de/simulator",
    meta: simulatork6R9PxQ47nMeta || {},
    alias: simulatork6R9PxQ47nMeta?.alias || [],
    redirect: simulatork6R9PxQ47nMeta?.redirect,
    component: () => import("/app/pages/simulator.vue").then(m => m.default || m)
  },
  {
    name: simulatork6R9PxQ47nMeta?.name ?? "simulator___en",
    path: simulatork6R9PxQ47nMeta?.path ?? "/en/simulator",
    meta: simulatork6R9PxQ47nMeta || {},
    alias: simulatork6R9PxQ47nMeta?.alias || [],
    redirect: simulatork6R9PxQ47nMeta?.redirect,
    component: () => import("/app/pages/simulator.vue").then(m => m.default || m)
  },
  {
    name: simulatork6R9PxQ47nMeta?.name ?? "simulator___fr",
    path: simulatork6R9PxQ47nMeta?.path ?? "/fr/simulator",
    meta: simulatork6R9PxQ47nMeta || {},
    alias: simulatork6R9PxQ47nMeta?.alias || [],
    redirect: simulatork6R9PxQ47nMeta?.redirect,
    component: () => import("/app/pages/simulator.vue").then(m => m.default || m)
  },
  {
    name: indexOP43DqW8IuMeta?.name ?? "support-tickets___de",
    path: indexOP43DqW8IuMeta?.path ?? "/de/support-tickets",
    meta: indexOP43DqW8IuMeta || {},
    alias: indexOP43DqW8IuMeta?.alias || [],
    redirect: indexOP43DqW8IuMeta?.redirect,
    component: () => import("/app/pages/support-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: indexOP43DqW8IuMeta?.name ?? "support-tickets___en",
    path: indexOP43DqW8IuMeta?.path ?? "/en/support-tickets",
    meta: indexOP43DqW8IuMeta || {},
    alias: indexOP43DqW8IuMeta?.alias || [],
    redirect: indexOP43DqW8IuMeta?.redirect,
    component: () => import("/app/pages/support-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: indexOP43DqW8IuMeta?.name ?? "support-tickets___fr",
    path: indexOP43DqW8IuMeta?.path ?? "/fr/support-tickets",
    meta: indexOP43DqW8IuMeta || {},
    alias: indexOP43DqW8IuMeta?.alias || [],
    redirect: indexOP43DqW8IuMeta?.redirect,
    component: () => import("/app/pages/support-tickets/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93QD3qz9OzusMeta?.name ?? "trading-buy-depotid___de",
    path: _91_91depotid_93_93QD3qz9OzusMeta?.path ?? "/de/trading/buy/:depotid?",
    meta: _91_91depotid_93_93QD3qz9OzusMeta || {},
    alias: _91_91depotid_93_93QD3qz9OzusMeta?.alias || [],
    redirect: _91_91depotid_93_93QD3qz9OzusMeta?.redirect,
    component: () => import("/app/pages/trading/buy/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93QD3qz9OzusMeta?.name ?? "trading-buy-depotid___en",
    path: _91_91depotid_93_93QD3qz9OzusMeta?.path ?? "/en/trading/buy/:depotid?",
    meta: _91_91depotid_93_93QD3qz9OzusMeta || {},
    alias: _91_91depotid_93_93QD3qz9OzusMeta?.alias || [],
    redirect: _91_91depotid_93_93QD3qz9OzusMeta?.redirect,
    component: () => import("/app/pages/trading/buy/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93QD3qz9OzusMeta?.name ?? "trading-buy-depotid___fr",
    path: _91_91depotid_93_93QD3qz9OzusMeta?.path ?? "/fr/trading/buy/:depotid?",
    meta: _91_91depotid_93_93QD3qz9OzusMeta || {},
    alias: _91_91depotid_93_93QD3qz9OzusMeta?.alias || [],
    redirect: _91_91depotid_93_93QD3qz9OzusMeta?.redirect,
    component: () => import("/app/pages/trading/buy/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93b1OCaxcfHVMeta?.name ?? "trading-delivery-depotid___de",
    path: _91_91depotid_93_93b1OCaxcfHVMeta?.path ?? "/de/trading/delivery/:depotid?",
    meta: _91_91depotid_93_93b1OCaxcfHVMeta || {},
    alias: _91_91depotid_93_93b1OCaxcfHVMeta?.alias || [],
    redirect: _91_91depotid_93_93b1OCaxcfHVMeta?.redirect,
    component: () => import("/app/pages/trading/delivery/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93b1OCaxcfHVMeta?.name ?? "trading-delivery-depotid___en",
    path: _91_91depotid_93_93b1OCaxcfHVMeta?.path ?? "/en/trading/delivery/:depotid?",
    meta: _91_91depotid_93_93b1OCaxcfHVMeta || {},
    alias: _91_91depotid_93_93b1OCaxcfHVMeta?.alias || [],
    redirect: _91_91depotid_93_93b1OCaxcfHVMeta?.redirect,
    component: () => import("/app/pages/trading/delivery/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93b1OCaxcfHVMeta?.name ?? "trading-delivery-depotid___fr",
    path: _91_91depotid_93_93b1OCaxcfHVMeta?.path ?? "/fr/trading/delivery/:depotid?",
    meta: _91_91depotid_93_93b1OCaxcfHVMeta || {},
    alias: _91_91depotid_93_93b1OCaxcfHVMeta?.alias || [],
    redirect: _91_91depotid_93_93b1OCaxcfHVMeta?.redirect,
    component: () => import("/app/pages/trading/delivery/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93OjPU9sT6FEMeta?.name ?? "trading-sell-depotid___de",
    path: _91_91depotid_93_93OjPU9sT6FEMeta?.path ?? "/de/trading/sell/:depotid?",
    meta: _91_91depotid_93_93OjPU9sT6FEMeta || {},
    alias: _91_91depotid_93_93OjPU9sT6FEMeta?.alias || [],
    redirect: _91_91depotid_93_93OjPU9sT6FEMeta?.redirect,
    component: () => import("/app/pages/trading/sell/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93OjPU9sT6FEMeta?.name ?? "trading-sell-depotid___en",
    path: _91_91depotid_93_93OjPU9sT6FEMeta?.path ?? "/en/trading/sell/:depotid?",
    meta: _91_91depotid_93_93OjPU9sT6FEMeta || {},
    alias: _91_91depotid_93_93OjPU9sT6FEMeta?.alias || [],
    redirect: _91_91depotid_93_93OjPU9sT6FEMeta?.redirect,
    component: () => import("/app/pages/trading/sell/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93OjPU9sT6FEMeta?.name ?? "trading-sell-depotid___fr",
    path: _91_91depotid_93_93OjPU9sT6FEMeta?.path ?? "/fr/trading/sell/:depotid?",
    meta: _91_91depotid_93_93OjPU9sT6FEMeta || {},
    alias: _91_91depotid_93_93OjPU9sT6FEMeta?.alias || [],
    redirect: _91_91depotid_93_93OjPU9sT6FEMeta?.redirect,
    component: () => import("/app/pages/trading/sell/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93495L2tlMXiMeta?.name ?? "trading-transfer-depotid___de",
    path: _91_91depotid_93_93495L2tlMXiMeta?.path ?? "/de/trading/transfer/:depotid?",
    meta: _91_91depotid_93_93495L2tlMXiMeta || {},
    alias: _91_91depotid_93_93495L2tlMXiMeta?.alias || [],
    redirect: _91_91depotid_93_93495L2tlMXiMeta?.redirect,
    component: () => import("/app/pages/trading/transfer/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93495L2tlMXiMeta?.name ?? "trading-transfer-depotid___en",
    path: _91_91depotid_93_93495L2tlMXiMeta?.path ?? "/en/trading/transfer/:depotid?",
    meta: _91_91depotid_93_93495L2tlMXiMeta || {},
    alias: _91_91depotid_93_93495L2tlMXiMeta?.alias || [],
    redirect: _91_91depotid_93_93495L2tlMXiMeta?.redirect,
    component: () => import("/app/pages/trading/transfer/[[depotid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91depotid_93_93495L2tlMXiMeta?.name ?? "trading-transfer-depotid___fr",
    path: _91_91depotid_93_93495L2tlMXiMeta?.path ?? "/fr/trading/transfer/:depotid?",
    meta: _91_91depotid_93_93495L2tlMXiMeta || {},
    alias: _91_91depotid_93_93495L2tlMXiMeta?.alias || [],
    redirect: _91_91depotid_93_93495L2tlMXiMeta?.redirect,
    component: () => import("/app/pages/trading/transfer/[[depotid]].vue").then(m => m.default || m)
  }
]