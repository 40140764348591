import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/app/.nuxt/floating-vue.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import appexChart_client_uiDwQjUKF6 from "/app/plugins/appexChart.client.ts";
import loader_client_6YKmdReXI5 from "/app/plugins/loader.client.ts";
import modal_client_5sTXjXRW3L from "/app/plugins/modal.client.ts";
import slider_client_6xUBjfQdZi from "/app/plugins/slider.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  floating_vue_EIcJ7xiw0h,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  appexChart_client_uiDwQjUKF6,
  loader_client_6YKmdReXI5,
  modal_client_5sTXjXRW3L,
  slider_client_6xUBjfQdZi
]