export enum StorageKeys{
    planKey = "plan",
    accountInformation='accountInformation',
    conditions='conditions',
    subscriptionStep='subscriptionStep',
    emailValidated='EmailValidated',
    phoneValidated='phoneValidated',
    token='token',
    expirationDate="expirationDate",
    refreshToken="refreshToken",
    account="account",
    secret='secret',
    permanent='permanent',
    accountId='account_id',
    contact_id='contact_id',
    address='address',
    goldPrice='goldprice',
    silverPrice='silverprice',
    depotTypes = 'depottypes',
    depotToTrade = 'depotToTrade'
}